<template>
  <div class="notFound p-grid nested-grid p-ai-center p-m-0">
    <Card class="p-mx-auto p-col-6 p-md-4 p-lg-3 p-xl-3">
      <template #title>
        <div class="p-text-center">Страница не найдена</div>
      </template>
      <template #content>
        Странца, на которую вы попытались перейти, либо еще не существует, либо
        у вас нет к ней доступа. Вы можете попытаться войти в систему, либо
        перейти на главную страницу.
      </template>
      <template #footer>
        <Button
          @click="goToLoginPage"
          label="Войти в систему"
          class="p-col-12 p-button-raised p-mb-2"
          v-tooltip.right="
            'Вы перейдете на страницу авторизации (либо на главную страницу, если вы уже авторизованы)'
          "
        />
        <Button
          class="p-col-12 p-button-secondary"
          @click="goToMainPage"
          label="На главную"
          v-tooltip.right="
            'Вы перейдете на главную страницу сайта (либо на страницу авторизации)'
          "
        />
      </template>
    </Card>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import Button from "primevue/button";
import Card from "primevue/card";

export default {
  name: "not-found",
  components: {
    Button,
    Card,
  },
  methods: {
    goToLoginPage() {
      // will redirect to dashboard OR to login page
      this.$router.push({ name: "login" });
    },
    goToMainPage() {
      this.$router.push({ name: "dashboard" });
    },
  },
};
</script>

<style>
.notFound {
  background: #ccc;
  width: 100%;
  height: 100%;
}
</style>
